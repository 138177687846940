// Package Utilities
import { Col, Grid } from '@devstart/react-bootstrap';
import { graphql, withPrefix } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { ObserveKeys } from 'react-hotkeys';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

// Local Utilities
import Loader from '../../../components/helpers/loader';
import LearnLayout from '../../../components/layouts/learn';
import {
  AllChallengeNode,
  ChallengeAnswers,
  ChallengeAnswersProps,
  ChallengeMeta,
  ChallengeNode,
  User,
  Challenge
} from '../../../redux/prop-types';
import ChallengeDescription from '../components/challenge-description';
import ChallengeTitle from '../components/challenge-title';
import CompletionModal from '../components/completion-modal';
import Hotkeys from '../components/hotkeys';
import PrismFormatted from '../components/prism-formatted';
import VideoPlayer from '../components/video-player';
import {
  challengeMounted,
  openModal,
  submitChallenge,
  updateChallengeMeta,
  updateSolutionFormValues,
  isAllowedChallenge,
  saveUserAnswer
} from '../redux/actions';
import {
  challengeAnswersSelector,
  completedChallengesIdsSelector,
  isChallengeCompletedSelector,
  isCompletedChallengesLoading
} from '../redux/selectors';

// Styles
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import { FlashMessages } from '../../../components/Flash/redux/flash-messages';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../../redux/selectors';
import { hardGoTo as navigate, changeTourStatus } from '../../../redux/actions';
import BreadCrumb from '../components/bread-crumb';
import '../video.css';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import { RegistrationResponseProps } from '../../../utils/ajax';
import ChallengeNotAllowedModal from '../../../components/modals/challenge-not-allowed-modal';

// Redux Setup
const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  isSignedInSelector,
  signInLoadingSelector,
  completedChallengesIdsSelector,
  userSelector,
  challengeAnswersSelector,
  userRegistrationStatusSelector,
  isCompletedChallengesLoading,
  (
    isChallengeCompleted: boolean,
    isSignedIn: boolean,
    signInLoading: boolean,
    completedChallengeIds: string[],
    user: User,
    challengeAnswers: ChallengeAnswers[],
    userRegistrationStatus: RegistrationResponseProps,
    isCompletedChallengesLoading: boolean
  ) => ({
    isChallengeCompleted,
    isSignedIn,
    signInLoading,
    completedChallengeIds,
    user,
    challengeAnswers,
    userRegistrationStatus,
    isCompletedChallengesLoading
  })
);
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      createFlashMessage,
      removeFlashMessage,
      challengeMounted,
      updateSolutionFormValues,
      openCompletionModal: () => openModal('completion'),
      submitChallenge,
      navigate,
      isAllowedChallenge,
      changeTourStatus,
      saveUserAnswer
    },
    dispatch
  );

// Types
interface ShowVideoProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode; allChallengeNode: AllChallengeNode };
  completedChallengeIds: string[];
  description: string;
  isSignedIn: boolean;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  signInLoading: boolean;
  navigate: (location: string) => void;
  user: User;
  isChallengeCompleted: boolean;
  openCompletionModal: () => void;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  isAllowedChallenge: (challenge: Challenge) => void;
  saveUserAnswer: ChallengeAnswersProps;
  challengeAnswers: ChallengeAnswers[];
  userRegistrationStatus: RegistrationResponseProps;
  isCompletedChallengesLoading: boolean;
}

interface ShowVideoState {
  subtitles: string;
  downloadURL: string | null;
  selectedOption: number | null;
  answer: number;
  showRight: boolean;
  showWrong: boolean;
  videoIsLoaded: boolean;
}

// Component
class ShowVideo extends Component<ShowVideoProps, ShowVideoState> {
  static displayName: string;
  private _container: HTMLElement | null | undefined;

  constructor(props: ShowVideoProps) {
    super(props);
    this.state = {
      subtitles: '',
      downloadURL: null,
      selectedOption: null,
      answer: 1,
      showWrong: false,
      showRight: false,
      videoIsLoaded: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: {
            id,
            title,
            challengeType,
            helpCategory,
            question: { solution }
          }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta,
      user,
      challengeAnswers
    } = this.props;

    this.selectUserAnswer(
      challengeAnswers,
      user.challengeAnswers,
      solution,
      id
    );

    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ShowVideoProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge,
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta,
      isSignedIn,
      isCompletedChallengesLoading
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);

      if (!isCompletedChallengesLoading) {
        isAllowedChallenge(challenge);
        if (!isSignedIn) {
          navigate(withPrefix('/'));
        }
      }
    }
  }

  componentWillUnmount(): void {
    const { removeFlashMessage } = this.props;

    const slug = this.props.data.challengeNode.challenge.fields.slug;
    if (window.location.pathname !== slug) {
      removeFlashMessage();
    }
  }

  handleSubmit(
    solution: number,
    openCompletionModal: () => void,
    submitChallenge: () => void
  ) {
    if (solution - 1 === this.state.selectedOption) {
      this.setState({
        showWrong: false,
        showRight: true
      });
      submitChallenge();
      openCompletionModal();
    } else {
      this.setState({
        showWrong: true,
        showRight: false
      });
    }
  }

  handleOptionChange = (
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      showWrong: false,
      showRight: false,
      selectedOption: parseInt(changeEvent.target.value, 10)
    });
  };

  onVideoLoad = () => {
    this.setState({
      videoIsLoaded: true
    });
  };

  selectUserAnswer(
    localChallengeAnswers: ChallengeAnswers[],
    dbChallengeAnswers: ChallengeAnswers[],
    solution: number,
    currentChallengeId: string
  ) {
    const getAnswer = (answers: ChallengeAnswers[] | undefined) =>
      answers?.find(answer => answer.id === currentChallengeId)?.userAnswer;

    const localUserAnswer = getAnswer(localChallengeAnswers);
    const dbUserAnswer = getAnswer(dbChallengeAnswers);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isValidNumber = (value: any): value is number =>
      typeof value === 'number' && !isNaN(value);

    const userAnswerForQuiz = isValidNumber(localUserAnswer)
      ? localUserAnswer
      : dbUserAnswer;

    if (isValidNumber(userAnswerForQuiz)) {
      this.setState({ selectedOption: userAnswerForQuiz });

      const solutionIndex = solution - 1;

      this.setState({
        showWrong: solutionIndex !== userAnswerForQuiz,
        showRight: solutionIndex === userAnswerForQuiz
      });
    }
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge: {
            id,
            fields: { blockName },
            title,
            description,
            superBlock,
            certification,
            block,
            phase,
            translationPending,
            videoId,
            question: { text, answers, solution }
          }
        }
      },
      openCompletionModal,
      submitChallenge,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      createFlashMessage,
      removeFlashMessage,
      isChallengeCompleted,
      saveUserAnswer
    } = this.props;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;
    const ariaLabel = t('aria.answer');

    return (
      <Hotkeys
        executeChallenge={() => {
          removeFlashMessage();
          this.handleSubmit(solution, openCompletionModal, submitChallenge);
        }}
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVStart`}
          />
          <Grid className='navigation-container-video'>
            <BreadCrumb
              block={block}
              phase={phase}
              superBlock={superBlock}
              video={true}
              challengeTitle={title}
            />
          </Grid>
          <Grid className='container-video'>
            <ChallengeTitle
              isCompleted={isChallengeCompleted}
              isCentered
              translationPending={translationPending}
            >
              {title}
            </ChallengeTitle>
            <ChallengeDescription description={description} />
            <Col sm={12} style={{ padding: 0 }}>
              <div className='video-wrapper'>
                {!this.state.videoIsLoaded ? (
                  <div className='video-placeholder-loader'>
                    <Loader />
                  </div>
                ) : null}
                <VideoPlayer videoId={videoId} />
              </div>
            </Col>
            <Col sm={12} style={{ padding: 0 }}>
              <PrismFormatted className='line-numbers' text={text} />
              <ObserveKeys>
                <div className='video-quiz-options'>
                  {answers.map((option, index) => {
                    const isSelected = this.state.selectedOption === index;
                    const isWrongAnswer = isSelected && this.state.showWrong;
                    const isRightAnswer = isSelected && this.state.showRight;

                    return (
                      // answers are static and have no natural id property, so
                      // index should be fine as a key:
                      <label
                        className='video-quiz-option-label'
                        id={
                          isWrongAnswer
                            ? 'video-quiz-option-label-wrong'
                            : isRightAnswer
                            ? 'video-quiz-option-label-right'
                            : undefined
                        }
                        key={index}
                      >
                        <input
                          aria-label={ariaLabel}
                          checked={isSelected}
                          className='video-quiz-input-hidden'
                          name='quiz'
                          onChange={this.handleOptionChange}
                          type='radio'
                          value={index}
                        />{' '}
                        <span className='video-quiz-input-visible'>
                          {isSelected ? (
                            <span className='video-quiz-selected-input' />
                          ) : null}
                        </span>
                        <PrismFormatted
                          className={'video-quiz-option'}
                          text={option}
                        />
                      </label>
                    );
                  })}
                  <Button
                    id='big-button'
                    buttonType={ButtonTypes.Primary}
                    onClick={() => {
                      removeFlashMessage();

                      if (this.state.selectedOption == null) {
                        createFlashMessage({
                          message: FlashMessages.ChallengeNotAnswered,
                          type: 'danger'
                        });
                      } else {
                        const answerPayload: ChallengeAnswers = {
                          id,
                          userAnswer: this.state.selectedOption ?? undefined
                        };

                        saveUserAnswer(answerPayload);

                        if (this.state.selectedOption != solution - 1) {
                          createFlashMessage({
                            message: FlashMessages.ChallengeWrongAnswer,
                            type: 'danger'
                          });
                        }

                        this.handleSubmit(
                          solution,
                          openCompletionModal,
                          submitChallenge
                        );
                      }
                    }}
                  >
                    {t('buttons.check-answer')}
                  </Button>
                </div>
              </ObserveKeys>
            </Col>
            <CompletionModal
              block={block}
              blockName={blockName}
              certification={certification}
              superBlock={superBlock}
            />
            <ChallengeNotAllowedModal />
          </Grid>
        </LearnLayout>
      </Hotkeys>
    );
  }
}

ShowVideo.displayName = 'ShowVideo';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShowVideo));

export const query = graphql`
  query VideoChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        id
        videoId
        title
        phase
        description
        challengeType
        helpCategory
        superBlock
        certification
        superOrder
        phaseOrder
        order
        challengeOrder
        block
        fields {
          blockName
          slug
        }
        question {
          text
          answers
          solution
        }
        translationPending
      }
    }
  }
`;
